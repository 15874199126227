import { Fragment, useContext, useState } from "react";
import { Button, Table, Icon } from "semantic-ui-react";
import Utils from "../../utils/Utils";
import AuthContext from "../../store/auth-context";
import UpdateCustomerPointsModal from "./UpdateCustomerPointsModal";

const CustomerDetailsTable = (props) => {
  const authCtx = useContext(AuthContext);

  const [updateEvent, setUpdateEvent] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const addPoints = () => {
    setIsModalOpen(true);
    setUpdateEvent(Utils.ADD_CUSTOMER_POINTS);
  };

  const subtractPoints = () => {
    setIsModalOpen(true);
    setUpdateEvent(Utils.SUBTRACT_CUSTOMER_POINTS);
  };

  const handleOnCloseModal = (needsReload) => {
    setIsModalOpen(false);
    if (needsReload) {
      props.invokeSearch();
    }
  };

  return (
    <Fragment>
      <fieldset className="fieldset">
        <legend>Appointment History</legend>
        <Table className="borderNone">
          <Table.Body>
            <Table.Row>
              <Table.Cell>Visit Count:</Table.Cell>
              <Table.Cell>
                {props.customer.visitCount ? props.customer.visitCount : "0"}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Cancel Count:</Table.Cell>
              <Table.Cell>
                {props.customer.cancelCount ? props.customer.cancelCount : "0"}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <div>Expired Points:</div>
                <sub>Earned before Jan 1, 2025</sub>
              </Table.Cell>
              <Table.Cell>
                {props.customer.expiredPointsApril12025
                  ? props.customer.expiredPointsApril12025
                  : "0"}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Available Points:</Table.Cell>
              <Table.Cell>
                {props.customer.pointsEarned
                  ? props.customer.pointsEarned
                  : "0"}
                {authCtx.getRoles().includes("SUPER_ADMIN") && (
                  <span style={{ paddingLeft: "20px" }}>
                    <Button
                      secondary
                      size="mini"
                      style={{ width: "100px" }}
                      onClick={addPoints}
                    >
                      <Icon name="add" />
                      ADD
                    </Button>
                    <Button size="mini" secondary onClick={subtractPoints}>
                      <Icon name="minus" />
                      SUBTRACT
                    </Button>
                  </span>
                )}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Appointments:</Table.Cell>
              <Table.Cell>
                {!props.customer.appointments ||
                props.customer.appointments.length === 0 ? (
                  <Fragment>No past appointments</Fragment>
                ) : (
                  <Fragment>
                    <ul>
                      {props.customer.appointments.map((appointment, index) => (
                        <li key={index} style={{ fontSize: "10px" }}>
                          <div>
                            {`${appointment.service} ${
                              appointment.staff
                                ? `with ${appointment.staff}`
                                : ""
                            } on ${Utils.formatDateTime(appointment.start)}`}
                          </div>
                          {appointment.status !== "CANCELLED" ? (
                            <div>
                              {`${appointment.status} | ${
                                appointment.pointsEarned
                                  ? appointment.pointsEarned
                                  : 0
                              } pts earned | ${
                                appointment.pointsRedeemed
                                  ? appointment.pointsRedeemed
                                  : 0
                              } pts redeemed`}
                            </div>
                          ) : (
                            <div>{appointment.status}</div>
                          )}
                        </li>
                      ))}
                    </ul>
                  </Fragment>
                )}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Update Points History:</Table.Cell>
              <Table.Cell>
                {!props.customer.updateCustomerPointsLogs ||
                props.customer.updateCustomerPointsLogs.length === 0 ? (
                  <Fragment>No updates on points by Admin</Fragment>
                ) : (
                  <Fragment>
                    <ul>
                      {props.customer.updateCustomerPointsLogs.map(
                        (updateCustomerPointsLog, index) => (
                          <li key={index} style={{ fontSize: "10px" }}>
                            {`${updateCustomerPointsLog.action} ${
                              updateCustomerPointsLog.points
                            } by ${
                              updateCustomerPointsLog.createdBy
                            } on ${Utils.formatDateTime(
                              updateCustomerPointsLog.createdDateTime
                            )}`}
                          </li>
                        )
                      )}
                    </ul>
                  </Fragment>
                )}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell
                colSpan={2}
              >{`Created by ${props.customer.createdBy} on ${props.customer.createdDateTime}`}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell
                colSpan={2}
              >{`Last updated by ${props.customer.lastUpdatedBy} on ${props.customer.updatedDateTime}`}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </fieldset>
      {isModalOpen && (
        <UpdateCustomerPointsModal
          isModalOpen={isModalOpen}
          handleOnCloseModal={handleOnCloseModal}
          event={updateEvent}
          customer={props.customer}
        ></UpdateCustomerPointsModal>
      )}
    </Fragment>
  );
};

export default CustomerDetailsTable;
