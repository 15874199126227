class Utils {
  static BLANK = "-----";
  static EVENT_ADD = "EVENT_ADD";
  static EVENT_UPDATE = "EVENT_UPDATE";

  static ADD_CUSTOMER_POINTS = "ADD_CUSTOMER_POINTS";
  static SUBTRACT_CUSTOMER_POINTS = "SUBTRACT_CUSTOMER_POINTS";

  static INVALID_NAME_CHARACTERS = [
    "&",
    "!",
    "@",
    "#",
    "(",
    ")",
    "*",
    ";",
    "[",
    "]",
  ];

  static convertToAMPM(timeString) {
    const [hours, minutes] = timeString.split(":");
    const time = new Date();
    time.setHours(hours);
    time.setMinutes(minutes);
    const ampm = time.getHours() >= 12 ? "PM" : "AM";
    const formattedHours = time.getHours() % 12 || 12;
    const formattedMinutes = time.getMinutes().toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}${ampm}`;
  }

  static formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  static formatDateTime(dateTimeString) {
    const datetime = new Date(dateTimeString);

    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    return datetime.toLocaleString("en-US", options);
  }

  static getDateToday() {
    const options = {
      timeZone: "Asia/Singapore",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    return new Date()
      .toLocaleString("en-US", options)
      .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
  }

  static convertToDescriptiveDate(inputDateStr) {
    const weekdays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const date = new Date(inputDateStr);
    const dayOfWeek = weekdays[date.getDay()];
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      date
    );
    const day = date.getDate();
    return `${dayOfWeek} ${month} ${day}, ${date.getFullYear()}`;
  }

  static getPropsName(id, array) {
    let name = "-";
    array.some((value) => {
      if (value.id === id) {
        name = value.name;
        return true; // stops iteration
      }
      return false;
    });
    return name;
  }

  static getObject(id, array) {
    let obj = {};
    array.some((value) => {
      if (value.id === id) {
        obj = value;
        return true; // stops iteration
      }
      return false;
    });
    return obj;
  }

  static toAppointment(appointmentResponse) {
    return {
      id: appointmentResponse.id,
      customerId: appointmentResponse.customerId,
      name: appointmentResponse.name,
      mobileNumber: appointmentResponse.mobileNumber,
      appointmentCreationPaymentAmount:
        appointmentResponse.appointmentCreationPaymentAmount,
      appointmentCreationPaymentMOP:
        appointmentResponse.appointmentCreationPaymentMOP,
      balanceAfterReservation: appointmentResponse.balanceAfterReservation,
      checkInPaymentAmount: appointmentResponse.checkInPaymentAmount,
      checkInPaymentMOP: appointmentResponse.checkInPaymentMOP,
      checkInDateTime: appointmentResponse.checkInDateTime
        ? this.formatDateTime(appointmentResponse.checkInDateTime)
        : "-",
      checkInBy: appointmentResponse.checkInBy
        ? appointmentResponse.checkInBy
        : "-",
      hasCheckInPercentCharge: appointmentResponse.hasCheckInPercentCharge,
      checkInPercentChargeAmount:
        appointmentResponse.checkInPercentChargeAmount,
      balanceAfterCheckIn: appointmentResponse.balanceAfterCheckIn,
      completedPaymentAmount: appointmentResponse.completedPaymentAmount,
      completedPaymentMOP: appointmentResponse.completedPaymentMOP,
      completedDateTime: appointmentResponse.completedDateTime
        ? this.formatDateTime(appointmentResponse.completedDateTime)
        : "-",
      completedBy: appointmentResponse.completedBy
        ? appointmentResponse.completedBy
        : "-",
      additionalPaymentAmount: appointmentResponse.additionalPaymentAmount,
      additionalPaymentMOP: appointmentResponse.additionalPaymentMOP,
      pointsRedeemed: appointmentResponse.pointsRedeemed,
      pointsEarned: appointmentResponse.pointsEarned,
      actualPrice: appointmentResponse.actualPrice,
      originalPrice: appointmentResponse.originalPrice,
      totalPricePaid: appointmentResponse.totalPricePaid,
      staffCommission: appointmentResponse.staffCommission,
      startDate: appointmentResponse.startDate,
      startTime: appointmentResponse.startTime,
      endDate: appointmentResponse.endDate,
      endTime: appointmentResponse.endTime,
      resourceId: appointmentResponse.staffId,
      staffRequested: appointmentResponse.staffRequested,
      serviceIds: appointmentResponse.serviceIds,
      serviceOfferedType: appointmentResponse.serviceOfferedType,
      roomId: appointmentResponse.roomId,
      lockerId: appointmentResponse.lockerId,
      promoId: appointmentResponse.promoId,
      discount: appointmentResponse.discount,
      type: appointmentResponse.type,
      status: appointmentResponse.status,
      note: appointmentResponse.note,
      start: appointmentResponse.start
        ? new Date(appointmentResponse.start.toString())
        : appointmentResponse.start,
      end: appointmentResponse.end
        ? new Date(appointmentResponse.end.toString())
        : appointmentResponse.end,
      cancelReason: appointmentResponse.cancelReason,
      customerInitiatedCancel: appointmentResponse.customerInitiatedCancel,
      createdBy: appointmentResponse.createdBy
        ? appointmentResponse.createdBy
        : "-",
      lastUpdatedBy: appointmentResponse.lastUpdatedBy
        ? appointmentResponse.lastUpdatedBy
        : "-",
      createdDateTime: appointmentResponse.createdDateTime
        ? this.formatDateTime(appointmentResponse.createdDateTime)
        : "-",
      updatedDateTime: appointmentResponse.updatedDateTime
        ? this.formatDateTime(appointmentResponse.updatedDateTime)
        : "-",
      productsOrdered: appointmentResponse.productsOrdered,
      productPaymentAmount: appointmentResponse.productPaymentAmount,
      productPaymentMOP: appointmentResponse.productPaymentMOP,
    };
  }

  static toRoom(roomResponse) {
    return {
      id: roomResponse.id,
      name: roomResponse.name
        ? roomResponse.name.toUpperCase()
        : roomResponse.name,
      available: roomResponse.available,
      serviceIds: roomResponse.serviceIds,
      type: roomResponse.type,
      createdBy: roomResponse.createdBy ? roomResponse.createdBy : "-",
      lastUpdatedBy: roomResponse.lastUpdatedBy
        ? roomResponse.lastUpdatedBy
        : "-",
      createdDateTime: roomResponse.createdDateTime
        ? this.formatDateTime(roomResponse.createdDateTime)
        : "-",
      updatedDateTime: roomResponse.updatedDateTime
        ? this.formatDateTime(roomResponse.updatedDateTime)
        : "-",
    };
  }

  static toStaff(staffResponse) {
    return {
      id: staffResponse.id,
      name: staffResponse.name
        ? staffResponse.name.toUpperCase()
        : staffResponse.name,
      available: staffResponse.available,
      serviceCommissionMap: staffResponse.serviceCommissionMap,
      daysOff: staffResponse.daysOff,
      dayOffNow: staffResponse.dayOffNow,
      type: staffResponse.type,
      staffBreaks: staffResponse.staffBreaks,
      staffScheduleType: staffResponse.staffScheduleType,
      createdBy: staffResponse.createdBy ? staffResponse.createdBy : "-",
      lastUpdatedBy: staffResponse.lastUpdatedBy
        ? staffResponse.lastUpdatedBy
        : "-",
      createdDateTime: staffResponse.createdDateTime
        ? this.formatDateTime(staffResponse.createdDateTime)
        : "-",
      updatedDateTime: staffResponse.updatedDateTime
        ? this.formatDateTime(staffResponse.updatedDateTime)
        : "-",
    };
  }

  static toService(serviceResponse) {
    return {
      id: serviceResponse.id,
      name: serviceResponse.name
        ? serviceResponse.name.toUpperCase()
        : serviceResponse.name,
      available: serviceResponse.available,
      price: serviceResponse.price,
      duration: serviceResponse.duration,
      breakDuration: serviceResponse.breakDuration,
      type: serviceResponse.type,
      createdBy: serviceResponse.createdBy ? serviceResponse.createdBy : "-",
      lastUpdatedBy: serviceResponse.lastUpdatedBy
        ? serviceResponse.lastUpdatedBy
        : "-",
      createdDateTime: serviceResponse.createdDateTime
        ? this.formatDateTime(serviceResponse.createdDateTime)
        : "-",
      updatedDateTime: serviceResponse.updatedDateTime
        ? this.formatDateTime(serviceResponse.updatedDateTime)
        : "-",
    };
  }

  static toLocker(lockerResponse) {
    return {
      id: lockerResponse.id,
      name: lockerResponse.name
        ? lockerResponse.name.toUpperCase()
        : lockerResponse.name,
      available: lockerResponse.available,
      createdBy: lockerResponse.createdBy ? lockerResponse.createdBy : "-",
      lastUpdatedBy: lockerResponse.lastUpdatedBy
        ? lockerResponse.lastUpdatedBy
        : "-",
      createdDateTime: lockerResponse.createdDateTime
        ? this.formatDateTime(lockerResponse.createdDateTime)
        : "-",
      updatedDateTime: lockerResponse.updatedDateTime
        ? this.formatDateTime(lockerResponse.updatedDateTime)
        : "-",
    };
  }

  static toProduct(productResponse) {
    return {
      id: productResponse.id,
      name: productResponse.name,
      price: productResponse.price,
      type: productResponse.type,
      available: productResponse.available,
      createdBy: productResponse.createdBy,
      lastUpdatedBy: productResponse.lastUpdatedBy,
      createdDateTime: productResponse.createdDateTime
        ? this.formatDateTime(productResponse.createdDateTime)
        : "-",
      updatedDateTime: productResponse.updatedDateTime
        ? this.formatDateTime(productResponse.updatedDateTime)
        : "-",
    };
  }

  static toPromo(promoResponse) {
    return {
      id: promoResponse.id,
      name: promoResponse.name
        ? promoResponse.name.toUpperCase()
        : promoResponse.name,
      available: promoResponse.available,
      serviceLessPriceMap: promoResponse.serviceLessPriceMap,
      createdBy: promoResponse.createdBy ? promoResponse.createdBy : "-",
      lastUpdatedBy: promoResponse.lastUpdatedBy
        ? promoResponse.lastUpdatedBy
        : "-",
      createdDateTime: promoResponse.createdDateTime
        ? this.formatDateTime(promoResponse.createdDateTime)
        : "-",
      updatedDateTime: promoResponse.updatedDateTime
        ? this.formatDateTime(promoResponse.updatedDateTime)
        : "-",
    };
  }

  static toCustomer(customerResponse) {
    return {
      id: customerResponse.id,
      firstName: customerResponse.firstName,
      lastName: customerResponse.lastName,
      mobileNumber: customerResponse.mobileNumber,
      email: customerResponse.email,
      birthDate: customerResponse.birthDate,
      type: customerResponse.type,
      status: customerResponse.status,
      contractEndDate: customerResponse.contractEndDate,
      twitter: customerResponse.twitter,
      instagram: customerResponse.instagram,
      pointsEarned: customerResponse.pointsEarned,
      expiredPointsApril12025: customerResponse.expiredPointsApril12025,
      visitCount: customerResponse.visitCount,
      cancelCount: customerResponse.cancelCount,
      appointments: customerResponse.appointments,
      createdBy: customerResponse.createdBy ? customerResponse.createdBy : "-",
      lastUpdatedBy: customerResponse.lastUpdatedBy
        ? customerResponse.lastUpdatedBy
        : "-",
      createdDateTime: customerResponse.createdDateTime
        ? this.formatDateTime(customerResponse.createdDateTime)
        : "-",
      updatedDateTime: customerResponse.updatedDateTime
        ? this.formatDateTime(customerResponse.updatedDateTime)
        : "-",
      updateCustomerPointsLogs: customerResponse.updateCustomerPointsLogs,
    };
  }

  static toUsers(response) {
    return {
      id: response.id,
      username: response.username,
      firstName: response.firstName,
      lastName: response.lastName,
      email: response.email,
      mobileNumber: response.mobileNumber,
      enableAccess: response.enableAccess,
      roles: response.roles,
      createdBy: response.createdBy ? response.createdBy : "-",
      lastUpdatedBy: response.lastUpdatedBy ? response.lastUpdatedBy : "-",
      createdDateTime: response.createdDate
        ? this.formatDateTime(response.createdDate)
        : "-",
      updatedDateTime: response.updatedDate
        ? this.formatDateTime(response.updatedDate)
        : "-",
    };
  }

  static toStaffBreaks(response) {
    return {
      id: response.id,
      display: response.display,
    };
  }

  static toStore(response) {
    return {
      id: response.id,
      name: response.name,
      code: response.code,
      address: response.address,
      openTime: response.openTime,
      closeTime: response.closeTime,
      loyaltyMinimumPointsToRedeem: response.loyaltyMinimumPointsToRedeem,
      loyaltyPointsToRedeemDivisibleBy:
        response.loyaltyPointsToRedeemDivisibleBy,
      loyaltyPointsConfig: response.loyaltyPointsConfig,
      createdBy: response.createdBy ? response.createdBy : "-",
      lastUpdatedBy: response.lastUpdatedBy ? response.lastUpdatedBy : "-",
      createdDateTime: response.createdDateTime
        ? this.formatDateTime(response.createdDateTime)
        : "-",
      updatedDateTime: response.updatedDateTime
        ? this.formatDateTime(response.updatedDateTime)
        : "-",
    };
  }

  static generatePointsToRedeemDropdown(minimumPoints, divisbleBy, value) {
    const options = [];
    for (let i = minimumPoints; i <= value; i += divisbleBy) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  }

  static getDaysOfWeekOptions() {
    return [
      { key: "MON", text: "MON", value: "MON" },
      { key: "TUE", text: "TUE", value: "TUE" },
      { key: "WED", text: "WED", value: "WED" },
      { key: "THU", text: "THU", value: "THU" },
      { key: "FRI", text: "FRI", value: "FRI" },
      { key: "SAT", text: "SAT", value: "SAT" },
      { key: "SUN", text: "SUN", value: "SUN" },
    ];
  }

  static getStaffBreakDisplay(staffBreaks, id) {
    if (staffBreaks && id) {
      for (const staffBreak of staffBreaks) {
        if (id === staffBreak.id) {
          return staffBreak.display;
        }
      }
    }

    return "";
  }
}

export default Utils;
